import { ap, cr, ac, dc, on } from './dom';
import input from './input';
import is from '../util/is';
import button from './button';

const checkbox = (opts, attrs) => {
  const wrapper = input(
    'input',
    Object.assign(opts, {
      className: 'c-checkbox' + (opts.className ? ' ' + opts.className : ''),
    }),
    Object.assign(
      {
        type: 'checkbox',
      },
      attrs,
    ),
  );

  const inputElement = wrapper.inputElement;

  const switcher = ap(
    cr('span', 'c-checkbox-switcher ' + (opts.onOff === false ? 'simple' : 'on-off')),
    cr('span', 'c-checkbox-switcher-thumb'),
  );

  const setChecked = (checked, callOnChange = true) => {
    inputElement.checked = checked;
    if (checked) {
      ac(wrapper.element, 'checked');
    } else {
      dc(wrapper.element, 'checked');
    }

    if (callOnChange && is.function(opts.onChange)) {
      opts.onChange({
        target: inputElement,
      });
    }
  };

  const checked = is.object(attrs) && attrs.checked;

  setChecked(checked, false);

  const click = () => {
    if (!inputElement.disabled) {
      setChecked(!inputElement.checked);
    }
  };

  let valueLabel = null;

  if (opts.valueLabel) {
    if (is.string(opts.valueLabel)) {
      valueLabel = cr('label', 'c-value-label', opts.valueLabel);
    } else {
      valueLabel = opts.valueLabel;
    }
  }

  let element;

  if (opts.clickValueLabel !== false) {
    element = ap(button(click, 'checkbox-wrapper'), valueLabel, switcher);
  } else {
    on(switcher, 'click', click);
    element = ap(cr(), valueLabel, switcher);
  }

  if (inputElement.nextSibling) {
    inputElement.parentNode.insertBefore(element, inputElement.nextSibling);
  } else {
    ap(wrapper.element, element);
  }

  wrapper.setValue = setChecked;

  return wrapper;
};

export default checkbox;
