import { ap, cr } from '../../lib/ui/dom';

export default () =>
  [
    {
      href: 'https://itunes.apple.com/no/app/teamalert/id1354897593?l=nb&amp;mt=8',
      src: '/static/images/appstore.png',
      alt: 'Download on the AppStore',
    },
    {
      href: 'https://play.google.com/store/apps/details?id=no.framweb.teamalert',
      src: '/static/images/google-play.png',
      alt: 'Get it on Google Play',
    },
  ].map((appLink) =>
    ap(
      cr('a', 'app-link', null, {
        href: appLink.href,
        title: appLink.alt,
      }),
      cr('img', 'u-inline-block', null, {
        width: 180,
        height: 60,
        src: appLink.src,
        alt: appLink.alt,
      }),
    ),
  );
