import { ap, cr } from './dom';
import img from './img';

/**
 * Creates an empty state message with an icon to let the user know that there
 * is no data here.
 *
 * @param {string} title the empty state title
 * @param {string} message the empty state message
 * @param {src} iconSrc the src path of the empty state icon
 *
 * @returns {HTMLDivElement} a box containing the empty state message
 */
const emptyState = (title, message, iconSrc) =>
  ap(
    cr('div', 'c-empty-state'),
    img(iconSrc, 'icon', title),
    cr('h3', 'title', title),
    cr('p', 'message', message),
  );

export default emptyState;
