import { cr, ap } from './dom';

export const BANNER_INFO = 'info';
export const BANNER_WARN = 'warn';
export const BANNER_ERROR = 'error';

export const BANNER_LEVELS = [BANNER_INFO, BANNER_WARN, BANNER_ERROR];

const banner = (content, level = BANNER_INFO) => {
  if (BANNER_LEVELS.indexOf(level) < 0) {
    level = BANNER_INFO;
  }

  return ap(cr('div', `c-banner ${level}`), content);
};

export default banner;
