import { ap, cr, ac, dc } from '@/lib/ui/dom';
import confirmDialog from '@/components/dialog/confirmDialog';
import { translate as t } from '@/util/i18n';
import router from '@/router';
import api from '@/util/api';
import advancedSelect from '@/lib/ui/advancedSelect';
import map from '@/map/map';
import i from '@/lib/ui/i';
import validationLabel from '@/lib/ui/validationLabel';
import is from '@/lib/util/is';
import button from '@/lib/ui/button';
import errorMessage from '@/util/errorMessage';
import textArea from '@/lib/ui/textArea';
import getUserProfile from '@/util/getUserProfile';
import safeGet from '@/lib/util/safeGet';
import { PLAN_FREE } from '@/consts/plans';
import messageDialog from '@/components/dialog/messageDialog';
import mapPanel from '@/views/newPositioning/mapPanel';
import disappear from '@/lib/ui/disappear';
import { hide, show } from '@/lib/ui/showAndHide';
import checkbox from '@/lib/ui/checkbox';
import banner, { BANNER_WARN } from '@/lib/ui/banner';
import appLinks from '@/lib/ui/appLinks';
import emptyState from '@/lib/ui/emptyState';

const getId = (object) => object.id;
const idList = (array) => array.map(getId);

const newPositioning = () => {
  const rootElement = cr('section', 'c-new-alert c-new-positioning');

  // API CALLS

  const sendPositioning = () => {
    if (!validateForm()) {
      return;
    }

    confirmDialog(
      t('newPositioningSend'),
      t('newPositioningSendMsg'),
      t('cancel'),
      t('newPositioningSend'),
    )
      .open()
      .then((confirm) => {
        if (confirm) {
          api
            .sendPositioning({
              company: form.company,
              teams: form.company ? [] : idList(form.teams),
              locations: idList(form.locations),
              polygon: form.polygon,
              reason: form.reason,
            })
            .then((res) => {
              const alertId = res.alertId || res.AlertId;
              openPositionLog(alertId);
            })
            .catch((res) =>
              errorMessage(t('newPositioningSendError'), t('newPositioningSendErrorMsg'), res),
            );
        }
      });
  };

  const noDevicesBannerWrapper = cr();

  const getUsers = () => {
    api.getUsers().then((res) => {
      const users = res.users;
      let devicesCount = 0;
      users.forEach((user) => {
        devicesCount += user.deviceCount;
      });
      if (devicesCount < 1) {
        ap(
          noDevicesBannerWrapper,
          banner([cr('p', null, t('newPositioningNoDevices')), appLinks()], BANNER_WARN),
        );
        sendPositioningButton.disabled = true;
      }
    });
  };

  const openPositionLog = (alertId) => {
    import(
      /* webpackChunkName: "positioningResults" */ '@/views/newPositioning/positioningResults'
    ).then((module) => {
      module.default(rootElement, alertId);
    });
  };

  const teamsEmptyState = emptyState(
    t('noTeams'),
    t('newPositioningNoTeamsMsg'),
    '/static/images/empty-states/teams.svg',
  );
  hide(teamsEmptyState);

  const reloadTeams = () => {
    api.getTeams().then((res) => {
      const teams = res.teams;
      if (teams.length > 0) {
        teamsField.updateOptions(teams);
      } else {
        hide(teamsField.element);
        show(teamsEmptyState);
        companyWideField.setValue(true);
        companyWideField.inputElement.disabled = true;
      }
    });
  };

  const reloadLocations = () => {
    api.getLocations().then((res) => {
      const locations = res.locations;
      locationsField.updateOptions(locations);

      locationsMap.loaded.then(() => {
        locationsMap.clearLocations();
        locations.forEach((location) => {
          const onClick = () => {
            const checked = locationsField.toggleSelect(location.id);
            locationsMap.getLocations().forEach((mapLocation) => {
              if (mapLocation.id === location.id) {
                mapLocation.setChecked(checked);
              }
            });
          };

          locationsMap.addLocation(
            {
              geofence: location.geofence,
              id: location.id,
              checked: location.id === form.locations.id,
              marker: {
                name: location.name,
                onClick,
              },
              onClick,
            },
            false,
          );
        });
        if (locations.length > 0) {
          locationsMap.fitLocations();
        }
      });
    });
  };

  // FORM OBJECT

  const form = {
    company: false,
    teams: [],
    locations: [],
    polygon: '',
    reason: '',
  };

  // FORM CHANGES

  const formElement = cr('div', 'c-form');
  const locationsMap = map({
    search: true,
  });

  const resetField = (key) => {
    if (['company', 'teams', 'locations', 'polygon'].indexOf(key) >= 0) {
      recipientsValidationLabel.hide();
    }
    if (['reason'].indexOf(key) >= 0) {
      reason.setValidity(true);
    }
  };

  const onFormChange = (event) => {
    const target = event.target;
    const key = target.name;
    let value;

    if (target.type === 'checkbox') {
      value = target.checked;
    } else {
      value = target.value;
    }

    if (key === 'locations') {
      value = [value];
    }

    resetField(key);

    form[key] = value;
  };

  const toggleTargetSelector = (event) => {
    const checked = event.target.checked;
    const toggle = checked ? hide : show;
    toggle(teamsField.element);
    onFormChange(event);
  };

  const onLocationsChange = (event) => {
    const selected = event.target.value;
    locationsMap.getLocations().forEach((mapLocation) => {
      mapLocation.setChecked(selected && mapLocation.id === selected.id);
    });

    onFormChange(event);
  };

  // FIELDS

  const companyWideField = checkbox(
    {
      valueLabel: t('companyWide'),
      helperText: t('newAlertCompanyWideHelperText'),
      onChange: toggleTargetSelector,
    },
    {
      name: 'company',
      checked: form.company,
    },
  );

  const teamsField = advancedSelect(
    {
      labelText: [i('users'), ' ', t('teams')],
      helperText: t('newAlertTeamsHelperText'),
      onChange: onFormChange,
      className: 'c-new-alert-targets',
      noneText: t('none'),
      multiple: true,
      noResultsTxt: t('noResults'),
    },
    {
      name: 'teams',
      value: form.teams,
    },
  );
  const recipientsValidationLabel = validationLabel(t('invalidRecipients'));

  const mapContainer = cr('div', 'c-map-container');

  const mapPanelContainer = cr('div', 'map-panel-container');

  const startEditingMap = () => {
    ac(rootElement, 'show-map');
    ap(mapPanelContainer, mapPanel(locationsMap, form, finishEditingMap));
  };

  const finishEditingMap = (mapForm) => {
    dc(rootElement, 'show-map');
    disappear(mapPanelContainer.firstChild);

    if (mapForm) {
      form.locations = mapForm.locations;
      form.polygon = mapForm.polygon;
      updateLocationInfo();
    }
  };

  const locationInfo = cr('div', 'location-info');
  const locationInfoMap = map();
  hide(locationInfoMap.element);

  const updateLocationInfo = () => {
    locationInfo.innerHTML = '';
    if (form.locations.length) {
      const predefLocation = form.locations[0];
      ap(
        locationInfo,
        cr(
          'p',
          null,
          t('predefinedLocationSelected', null, {
            predefLocation: predefLocation.name,
          }),
        ),
      );

      show(locationInfoMap.element);
      locationInfoMap.redrawTiles();
      locationInfoMap.setCurrentDrawingFromString(predefLocation.geofence);
    } else if (form.polygon.length) {
      ap(locationInfo, cr('p', null, t('customLocationSelected')));

      show(locationInfoMap.element);
      locationInfoMap.redrawTiles();
      locationInfoMap.setCurrentDrawingFromString(form.polygon);
    } else {
      hide(locationInfoMap.element);
    }
  };

  const locationsField = advancedSelect(
    {
      labelText: [i('map-marker-alt'), ' ', t('locationPredefined')],
      helperText: t('locationsHelperText'),
      onChange: onLocationsChange,
      className: 'c-new-positioning-targets',
      noneText: t('none'),
      multiple: false,
      noResultsTxt: t('noResults'),
    },
    {
      name: 'locations',
      value: form.locations,
    },
  );

  const showMapButton = button(startEditingMap, 'show-map-button', t('selectInMap'));

  const reason = textArea(
    {
      labelText: t('reason'),
      helperText: t('reasonHelperText'),
      onChange: onFormChange,
      validation: t('reasonInvalid'),
    },
    {
      name: 'reason',
    },
  );

  // STEPS

  const recipientsStep = ap(
    cr('div', 'step'),
    cr('span', 'step-number', 1),
    cr('h2', 'u-typ-subheading', t('recipients')),
    companyWideField.element,
    teamsField.element,
    teamsEmptyState,
    recipientsValidationLabel.element,
  );

  const locationStep = ap(
    cr('div', 'step'),
    cr('span', 'step-number', 2),
    cr('h2', 'u-typ-subheading', `${t('location')} (${t('optional')})`),
    cr('p', 'u-typ-caption', t('locationsIntroText')),
    locationInfo,
    locationInfoMap.element,
    showMapButton,
  );

  const reasonStep = ap(
    cr('div', 'step'),
    cr('span', 'step-number', 3),
    cr('h2', 'u-typ-subheading', t('reason')),
    reason.element,
  );

  // VALIDATION

  const validateForm = () => {
    let valid = true;

    if (
      !form.company &&
      form.teams.length + form.locations.length < 1 &&
      !is.strNotEmpty(form.polygon)
    ) {
      valid = false;
      recipientsValidationLabel.show();
    }

    if (form.reason.length < 4) {
      valid = false;
      reason.setValidity(false);
    }

    return valid;
  };

  // LOAD CONTENT

  getUsers();
  reloadTeams();
  reloadLocations();

  // CHECK ACCESS

  getUserProfile().then((profile) => {
    const plan = safeGet(profile, 'customer', 'plan');
    if (plan === PLAN_FREE) {
      messageDialog(
        t('featureUnavailable'),
        t('featureUnavailablePositioning', null, {
          mail: 'sales@teamalert.net',
        }),
        t('ok'),
      )
        .open()
        .then(() => {
          router.push('/');
        });
    }
  });

  // RETURN

  const sendPositioningButton = button(sendPositioning, 'right', t('newPositioningSend'));

  return ap(
    rootElement,
    ap(mapContainer, mapPanelContainer, locationsMap.element),
    ap(
      cr('div', 'form-container'),
      ap(
        formElement,
        ap(
          cr('div', 'info-header'),
          cr('h1', 'u-typ-title', t('newPositioningPageTitle')),
          cr('p', 'u-typ-caption', t('newPositioningIntroText')),
          noDevicesBannerWrapper,
        ),
        ap(
          cr('div', 'page'),
          recipientsStep,
          locationStep,
          reasonStep,
          ap(cr('div', 'actions'), sendPositioningButton),
        ),
      ),
    ),
  );
};

export default newPositioning;
