import { st, on, rm, qa, cr, ap } from './dom';
import { yellowLight } from './colors';

const disappearElement = (element) =>
  new Promise((resolve) => {
    const height = element.clientHeight;

    on(element, 'transitionend', () => {
      rm(element);
      resolve();
    });

    st(element, {
      height: height + 'px',
      overflow: 'hidden',
      transition: 'all 0.3s cubic-bezier(0.35, 0, 0.25, 1)',
      backgroundColor: yellowLight,
    });

    setTimeout(() => {
      st(element, {
        height: 0,
        paddingTop: 0,
        paddingBottom: 0,
      });
    }, 100);
  });

const disappearTableCell = (tableCell) => {
  const childNodesArray = [];
  tableCell.childNodes.forEach((child) => childNodesArray.push(child));

  const ghostElement = ap(cr('div'), childNodesArray);

  st(ghostElement, {
    display: 'block',
  });

  tableCell.innerHTML = '';
  ap(tableCell, ghostElement);
  return Promise.all([disappearElement(tableCell), disappearElement(ghostElement)]);
};

/**
 *
 * @param {HTMLElement} element
 */
const disappear = (element) =>
  new Promise((resolve) => {
    if (element instanceof HTMLTableRowElement) {
      const tableCells = qa('td', element);
      const disappearPromises = [disappearElement(element)];
      tableCells.forEach((tableCell) => disappearPromises.push(disappearTableCell(tableCell)));
      Promise.all(disappearPromises).then(() => resolve());
    } else if (element instanceof HTMLTableCellElement) {
      disappearTableCell(element).then(() => resolve());
    } else {
      disappearElement(element).then(() => resolve());
    }
  });

export default disappear;
