<template>
  <div />
</template>

<script>
import newPositioning_module from './newPositioning_module';

export default {
  name: 'NewPositioning',

  mounted() {
    this.$el.append(newPositioning_module());
  },
};
</script>
