import { ap, cr, ac, dc } from '../lib/ui/dom';
import button from '../lib/ui/button';
import i from '../lib/ui/i';
import { translate as t } from '../util/i18n';
import is from '../lib/util/is';
import { show, hide } from '../lib/ui/showAndHide';

const mapTools = (map, done) => {
  const drawPolygon = () => {
    map.startDrawing('polygon');
  };

  const drawCircle = () => {
    map.startDrawing('circle');
  };

  const remove = () => {
    map.clearDrawing();
  };

  const cancel = () => {
    remove();
    done();
  };

  const save = () => {
    map.stopEditDrawing();
    done(map.getCurrentDrawingAsString(), true);
  };

  const drawPolygonButton = ap(button(drawPolygon, 'tool-button'), t('polygon'), i('draw-polygon'));
  const drawCircleButton = ap(button(drawCircle, 'tool-button'), t('circle'), i('circle'));
  const clearButton = button(remove, 'tool-button', t('clear'), {
    disabled: true,
  });
  const saveButton = button(save, 'tool-button', t('done'), {
    disabled: true,
  });

  map.on('editable:created', () => {
    clearButton.disabled = false;
    saveButton.disabled = false;
  });

  map.on('editable:disable', () => {
    clearButton.disabled = false;
  });

  const setTools = (tools) => {
    if (!tools) {
      tools = {};
    }

    if (tools.polygon) {
      show(drawPolygonButton);
    } else {
      hide(drawPolygonButton);
    }

    if (tools.circle) {
      show(drawCircleButton);
    } else {
      hide(drawCircleButton);
    }
  };

  const element = ap(
    cr('div', 'map-tools'),
    ap(cr('div', 'tool-container '), drawPolygonButton, drawCircleButton),
    ap(
      cr('div', 'tool-container menu'),
      button(cancel, 'tool-button', t('cancel')),
      clearButton,
      saveButton,
    ),
  );

  const showTools = () => {
    clearButton.disabled = !is.defined(map.getCurrentDrawing());
    saveButton.disabled = !is.defined(map.getCurrentDrawing());

    map.startEditDrawing();

    ac(element, 'show');
  };
  const hideTools = () => dc(element, 'show');

  return {
    show: showTools,
    hide: hideTools,
    element,
    setTools,
  };
};

export default mapTools;
