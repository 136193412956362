import button from '@/lib/ui/button';
import { cr, ap, ac, dc } from '@/lib/ui/dom';
import { translate as t } from '@/util/i18n';
import advancedSelect from '@/lib/ui/advancedSelect';
import i from '@/lib/ui/i';
import api from '@/util/api';
import mapTools from '@/map/mapTools';
import confirmDialog from '@/components/dialog/confirmDialog';

const mapPanel = (map, form, done) => {
  const mapForm = {
    locations: form.locations,
    polygon: form.polygon,
  };

  let customLocation = null;

  if (mapForm.polygon) {
    customLocation = mapForm.polygon;
    map.setCurrentDrawingFromString(customLocation);
  }

  const reloadLocations = () => {
    api.getLocations().then((res) => {
      locationsField.updateOptions(res.locations);
      if (mapForm.locations.length) {
        locationsField.setValue(mapForm.locations.map((predefLocation) => predefLocation.id));
      }
    });
  };

  const drawCustomLocation = () => {
    const draw = () => {
      tools.setTools({
        polygon: true,
        circle: true,
      });
      showTools();
      map.clearLocations();
    };

    if (mapForm.locations.length > 0) {
      confirmDialog(
        t('deselectPredefinedLocation'),
        t('deselectPredefinedLocationMsg'),
        t('cancel'),
        t('continue'),
      )
        .open()
        .then((confirm) => {
          if (confirm) {
            locationsField.clear(false);
            mapForm.locations = [];
            draw();
          }
        });
    } else {
      draw();
    }
  };

  const onLocationChange = (e) => {
    const handleChange = () => {
      const selectedLocation = e.target.value;

      map.clearLocations();

      if (selectedLocation) {
        mapForm.locations = [selectedLocation];
        map.addLocation({
          geofence: selectedLocation.geofence,
        });
      } else {
        mapForm.locations = [];
      }

      removeCustomLocation();
      updateCustomLocationActions();
    };

    if (customLocation) {
      confirmDialog(
        t('removeCustomLocation'),
        t('removeCustomLocationMsg'),
        t('cancel'),
        t('continue'),
      )
        .open()
        .then((confirm) => {
          if (confirm) {
            handleChange();
          } else {
            locationsField.clear(false);
          }
        });
    } else {
      handleChange();
    }
  };

  const locationsField = advancedSelect(
    {
      labelText: [i('map-marker-alt'), ' ', t('locationPredefined')],
      helperText: t('locationsHelperText'),
      onChange: onLocationChange,
      className: 'c-new-alert-targets',
      noneText: t('none'),
      multiple: false,
      noResultsTxt: t('noResults'),
    },
    {
      name: 'locations',
    },
  );

  const removeCustomLocation = () => {
    map.clearDrawing();
    customLocation = null;
    mapForm.polygon = '';
    updateCustomLocationActions();
  };

  const drawCustomLocationButton = button(drawCustomLocation);
  const removeCustomLocationButton = button(removeCustomLocation, null, i('trash'));

  const updateCustomLocationActions = () => {
    drawCustomLocationButton.innerHTML = customLocation
      ? t('editCustomLocation')
      : t('drawCustomLocation');
    removeCustomLocationButton.disabled = !customLocation;
  };

  updateCustomLocationActions();

  const locationContent = ap(
    cr(),
    cr('p', null, t('mapPanelHelperText')),
    ap(cr('div', 'custom-location-actions'), drawCustomLocationButton, removeCustomLocationButton),
    locationsField.element,
  );

  const showTools = () => {
    tools.show();
    ac(panel, 'hide');
  };
  const hideTools = (drawing, overwrite) => {
    tools.hide();
    dc(panel, 'hide');

    if (drawing || overwrite) {
      customLocation = drawing;
    }

    updateCustomLocationActions();

    if (customLocation && !drawing) {
      map.setCurrentDrawingFromString(customLocation);
    }

    mapForm.polygon = customLocation;

    reloadLocations();
  };
  const tools = mapTools(map, hideTools);

  const cancel = () => {
    map.clearDrawing();
    done();
  };

  const save = () => {
    done(mapForm);
    map.clearDrawing();
  };

  const panel = cr('div', 'map-panel');

  const rootElement = ap(
    cr('div', 'map-panel-container'),
    tools.element,
    ap(
      panel,
      locationContent,
      ap(
        cr('div', 'actions'),
        button(cancel, 'type-secondary', t('cancel')),
        button(save, null, t('done')),
      ),
    ),
  );

  reloadLocations();

  return rootElement;
};

export default mapPanel;
